<template>
    <v-card>
        <v-card-title class="white--text primary">Add profile milestone</v-card-title>
        <v-card-text>
            <!-- Select Profile -->
            <h4 class="mb-2">Select an initial template milestone profile to apply to a burst: </h4>
            <v-combobox
                v-model="selectedProfile"
                :items="profiles"
                item-text="name"
                item-value="id"
                label="Select a profile"
                return-object
                outlined
                required
                style="cursor: pointer;"
            />
            <!-- Select Campaign -->
                <h4 class="mb-2">Select that awesome campaign and burst: </h4>
                <v-row>
                <v-col cols="6">
                    <v-combobox
                        :disabled="selectedProfile === null"
                        v-model="selectedCampaign"
                        :items="campaigns"
                        item-text="name"
                        item-value="id"
                        label="Select a campaign"
                        return-object
                        outlined
                        required
                    />
                </v-col>
                <v-col cols="6">
                    <v-combobox
                        :disabled="selectedCampaign === null"
                        v-model="selectedBurst"
                        :items="campaignBursts"
                        item-text="name"
                        item-value="id"
                        label="Select a burst"
                        return-object
                        multiple
                        outlined
                        required
                    >
                        <template v-slot:no-data>
                            <p class="ml-3 mt-3">There's no bursts available to apply a milestone!</p>
                        </template>
                    </v-combobox>
                </v-col>
                </v-row>
        </v-card-text>
        <v-card-actions class="pb-6 mt-n4">
            <v-col
                cols="12"
                class="text-center ma-0 pa-0"
            >
                <v-btn
                    color="primary"
                    class="mr-3"
                    :loading="loading"
                    :disabled="loading"
                    @click="confirmProfile()">
                    Confirm
                </v-btn>
                <v-btn
                    color="red"
                    :loading="loading"
                    :disabled="loading"
                    @click="closeApplyDProfileToBurstDialog()">
                    Cancel
                </v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>
<script>
// Controllers 
import IssueTask from '@/services/controllers/IssueTask'
import CampaignController from '@/services/controllers/Campaign'
export default {
    name: 'ProgressBurstProfiles',
    props: {
        // Campaigns
        campaigns: {
            type: Array,
            default: () => []
        }
    },
    inject: ['closeApplyDProfileToBurstDialog'],
    // watch selected campaign and console.log it on change
    watch: {
        selectedCampaign: async function (val) {
            try {
                await CampaignController.getCampaign(val.id).then((res) => {
                    this.campaignBursts = res.data.campaignBursts
                    // Find each campaign burst in campaigns and attach the workflow status from campaigns in this.campaignbursts
                    this.campaignBursts.forEach((burst) => {
                        this.campaigns.forEach((campaign) => {
                            campaign.campaignBursts.forEach((campaignBurst) => {
                                if(burst.id === campaignBurst.id) {
                                    burst.workflowStatus = campaignBurst.workflowStatus
                                }
                            })
                        })
                    })
                    // check if campaignbursts has workflow status not 0 or null, dont show it
                    this.campaignBursts = this.campaignBursts.filter((burst) => {
                        return burst.workflowStatus.id === 0 || burst.workflowStatus === null
                    })
                })
            } catch(err) {
                console.log(err)
            }
        }
    },
    data: () => ({
        //Profile
        selectedProfile: null,
        profiles: [],
        // Campaign
        selectedCampaign: null,
        selectedBurst: null,
        campaignBursts: [],
        title: '',
        description: '',
        category: '',
        loading: false
    }),
    async created() {
        // Get all profiles
        await IssueTask.getDProfiles(false).then((res) => {
            this.profiles = res.data
        })
    },
    methods: {
        async confirmProfile() {
            // Check if all fields are filled
            if(this.selectedProfile === null || this.selectedCampaign === null || this.selectedBurst === null) {
                this.$root.$emit('snackbarWarning', 'Please select a profile, campaign and burst!')
                return
            }
            // Apply profile to burst
            try {
                // Iterate through selected bursts and apply profile
                this.loading = true
                this.selectedBurst.forEach(async (burst, index) => {
                    await IssueTask.applyDProfiles(this.selectedProfile.id, burst.id)
                    .then((res) => {
                        if(res.status === 200) {
                            this.$root.$emit('updateOrDeleteCampaign', 'update', res.data)
                            this.$root.$emit('snackbarSuccess', 'Profile applied to burst '+burst.name+' successfully!')
                        } else if(res.status === 201) {
                            this.$root.$emit('updateOrDeleteCampaign', 'update', res.data.result.result.value)
                            this.$root.$emit('snackbarWarning', res.data.warning)
                        }
                        if(index === this.selectedBurst.length - 1) {
                            this.closeApplyDProfileToBurstDialog()
                        }
                    })
                    .catch((err) => {
                        this.$root.$emit('snackbarError', ''+ err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                })


                /*await IssueTask.applyDProfiles(this.selectedProfile.id, this.selectedBurst.id).then((res) => {
                    if(res.status === 200) {
                        this.$root.$emit('updateOrDeleteCampaign', 'update', res.data)
                        this.closeApplyDProfileToBurstDialog()
                        this.$root.$emit('snackbarSuccess', 'Profile applied to burst '+this.selectedBurst.name+' successfully!')
                    } else if(res.status === 201) {
                        this.$root.$emit('updateOrDeleteCampaign', 'update', res.data.result.result.value)
                        this.closeApplyDProfileToBurstDialog()
                        this.$root.$emit('snackbarWarning', res.data.warning)
                    }
                })*/

            } catch(err) {
                // Ask Greg to return relevant message
                this.$root.$emit('snackbarError', ''+ err.response.data.message)
            }
        }
    }
}
</script>